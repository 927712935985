import axios from "axios";

const apiUrlUser = `${process.env.REACT_APP_SERVER_LINK}api/v1/`
const api = {
    auth: {
        login: (data) => axios.post(`${apiUrlUser}login`, data).then(res => res.data),
        register: (data) => axios.post(`${apiUrlUser}register`, data).then(res => res.data),
        me: (data) => axios.post(`${apiUrlUser}me`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data)
    },
    kite: {
        trading: {
            uploadTradingDetails: (data) => axios.post(`${apiUrlUser}add_kite_details`, data).then(res => res.data),
            fetchLiveData: (data) => axios.post(`${apiUrlUser}getLiveData`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data)
        },
        account: {
            fetch_kite_profile: (data) => axios.post(`${apiUrlUser}fetch_kite_profile`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            getMargins: (data) => axios.post(`${apiUrlUser}getMargins`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
        },
        auth: {
            performKiteLogin: (data) => axios.post(`${apiUrlUser}performKiteLogin`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data)
        },
        strategies: {
            fetchStrategyFamilies: (data) => axios.post(`${apiUrlUser}fetchStrategyFamilies`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            addStrategyFamily: (data) => axios.post(`${apiUrlUser}addStrategyFamily`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            addStrategy: (data) => axios.post(`${apiUrlUser}addStrategy`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            addStrategyFutures: (data) => axios.post(`${apiUrlUser}addStrategyFutures`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            fetchStrategies: (data) => axios.post(`${apiUrlUser}fetchStrategies`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            modifyStrategy: (data) => axios.post(`${apiUrlUser}modifyStrategy`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            modifyStrategyFutures: (data) => axios.post(`${apiUrlUser}modifyStrategyFutures`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            sliceFutures: (data) => axios.post(`${apiUrlUser}sliceFutures`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
        },
        returns: {
            getLnReturns: (data) => axios.post(`${apiUrlUser}showLogDiffs`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data)
        }
    },
    users: {
        fetch_users: (data) => axios.post(`${apiUrlUser}fetch_users`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data),
        fetch_count_strategies: (data) => axios.post(`${apiUrlUser}fetch_count_strategies`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data),
        future_expiry_notify: (data) => axios.post(`${apiUrlUser}future_expiry_notify`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data),
    },
    notifications: {
        web_push: {
            subscribe: (data) => axios.post(`${apiUrlUser}subscribe`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data),
            sendPushNotification: (data) => axios.post(`${apiUrlUser}sendPushNotification`, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            }).then(res => res.data)
        },
    },
    logs:{
        getStrategyLogs: (data) => axios.post(`${apiUrlUser}getStrategyLogs`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data),
    },
    analysis:{
        getStockEtfAnalysisList: (data) => axios.post(`${apiUrlUser}getStockEtfAnalysisList`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data),
        getTradesStockEtfAnalysis: (data) => axios.post(`${apiUrlUser}getTradesStockEtfAnalysis`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data),
        enableDisableAnalysis:(data)=> axios.post(`${apiUrlUser}updateTradesStatusAnalyis`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data),
        modifyAnalysis:(data)=> axios.post(`${apiUrlUser}modifyAnalyis`, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then(res => res.data),
    },
    upload:{
        manualEntryExitStFileUpload:(data)=> axios.post(`${apiUrlUser}uploadCsv`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: localStorage.getItem('token')
            },
        }).then(res => res.data),
    }
}

export default api;